<template>
  <p class="mb-0" v-bind="$attrs">{{extraWithQuantity}} = <i class="fal fa-dollar-sign"></i>{{cost}}</p>
</template>
<script>

export default {
  inheritAttrs: false,
  props: {
    extra: {
      type: Object,
      required: true
    }
  },
  computed: {
    extraWithQuantity() {
      if (this.extra.quantity > 1) {
        return this.extra.name + ' x ' + this.extra.quantity
      }
      return this.extra.name
    },
    cost() {
      return this.extra.basePrice + this.extra.perCost * this.usedQty
    },
    includedQty() {
      return this.extra.includedQty * 1
    },
    usedQty() {
      let qty = this.extra.quantity - this.includedQty
      if (qty < 0) { qty = 0 }
      return qty
    }
  }
}
</script>
