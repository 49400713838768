<script>
import ExtraForSummary from './extra_for_summary.vue'
import infos_mixin from '@/shared/infos_mixin'
import { formatCurrency } from '@/utils'
export default {
  mixins: [ infos_mixin ],
  props: {
    info: {
      type: Object,
      required: true
    },
    promoCode: {
      type: String,
      default: ''
    }
  },
  components: { ExtraForSummary },
  data() {
    return {
      forAdmins: false,
      promo_code_mutable: this.promoCode
    }
  },
  computed: {
    chargeParkingFee() {
      return this.info.chargeParkingFee
    },
    activeCoupon() {
        if (this.info.activeCoupon) {
            return this.info.activeCoupon
        }
    },
    discountInfo() {
        if (this.activeCoupon) {
            return "Discount: " + formatCurrency(this.discountAmount)
        }
    },
    discountInfo2() {
        if (this.activeCoupon) {
            return " Coupon '" + this.activeCoupon.code + "' " + this.activeCoupon.title
        }
    },
    discountAmount() {
        if (this.activeCoupon) {
            if (this.activeCoupon.amount_cents) {
                return this.activeCoupon.amount_cents / -100
            }
            if (this.activeCoupon.percentage > 0) {
                return this.totalWithoutDiscount * this.activeCoupon.percentage / -100
            }
        }
        return 0
    },
    extras() {
      return this.info.pickedExtras
    },
    extrasCost() {
      var cost = 0
      if (this.extras) {
        for(var extraIdx in this.extras) {
          const extra = this.extras[extraIdx]
          if (extra) {
            let usedQty = extra.quantity - extra.includedQty
            if (usedQty < 0) { usedQty = 0 }
            cost = cost + (extra.basePrice + extra.perCost * usedQty)
          }
        }
      }
      return cost
    },
    flexibleDescription() {
      return this.info.flexibleDescription
    },
    cleaningCost() {
      if (this.info.cleaning == 'CarpetCleaning') {
        return this.info.carpetCleaningPrice
      }
      if (this.info.prices && this.info.requestedCleaning) {
        return this.info.prices[this.info.requestedCleaning]
      }
      return 0
    },
    cleaningPlan() {
      if (this.info.cleaning == 'CarpetCleaning') {
        return 'Hot Water Extraction'
      }
      if (this.$i18n) {
        return this.$t(this.info.cleaning)
      }
    },
    isStartPicked() {
      return this.info.isStartPicked
    },
    isRecurring() {
      return (this.info.frequency == 'weekly') || (this.info.frequency == 'bi-weekly') || (this.info.frequency == 'monthly')
    },
    recurrence() {
      if (this.info.frequency == 'weekly') {
        return 'Weekly'
      }
      if (this.info.frequency == 'bi-weekly') {
        return 'Bi-Weekly'
      }
      if (this.info.frequency == 'monthly') {
        return 'Monthly'
      }
    },
    recurrencePrice() {
      return '(Upkeep price - $' + this.info.prices.upkeep + ')'
    },
    start() {
      return this.info.start
    },
    parkingFee() {
      if (this.chargeParkingFee) {
        return 4
      }
      return 0
    },
    property() {
      return this.info.property
    },
    propertyLink() {
      return '/properties/' + this.info.property.id
    },
    totalWithoutDiscount() {
      return this.cleaningCost + this.extrasCost + this.parkingFee
    },
    total() {
      const total = (this.totalWithoutDiscount + this.discountAmount).toFixed(2)
      this.$emit('update:total', total)
      return total
    },
    promo_code_m: {
      get() {
        return this.promo_code_mutable
      },
      set(v) {
        this.$emit('update:promo_code', v)
      }
    }
  },
  watch: {
    promoCode(new_value, old_value) {
      if (this.promo_code_mutable != new_value) {
        this.promo_code_mutable = new_value
      }
    }
  }
}
</script>
